html {
    scroll-behavior: smooth;
}

body {
    transition: all .5s linear;
    font-family: 'Work Sans', system-ui;
}

.shadow-default {
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.bounce {
    animation-name: bounce;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

@keyframes bounce {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-30px);
    }
    100% {
        transform: translateY(0px);
    }
}